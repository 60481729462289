// export const DATAUSAGE_URL = "http://10.147.20.101:5000/bsnl/"
// export const SPEEDTEST_URL = "http://192.168.1.111:5000/dummy/speedtest/"
// export const METAL_URL = "http://127.0.0.1:5001/metal/"
// export const FUEL_URL = "http://127.0.0.1:5001/fuel/"
// export const IP_URL = "http://127.0.0.1:5000/bsnl/ip/"
// export const DNS_URL = "http://127.0.0.1:5000/bsnl/dns/"
// export const LOG_URL = "http://localhost:5000/log/"


// export const DATAUSAGE_URL = "https://mybsnl.tk/bsnl/"
// export const SPEEDTEST_URL = "https://mybsnl.tk/speedtest/"
// export const METAL_URL = "https://api.bkapp.gq/metal/"
// export const FUEL_URL = "https://api.bkapp.gq/fuel/"
// export const IP_URL = "https://mybsnl.tk/bsnl/ip/"
// export const DNS_URL = "https://mybsnl.tk/bsnl/dns/"
// export const LOG_URL = "https://mybsnl.tk/log/"


export const DATAUSAGE_URL = "https://api.bkapp.gq/bsnl/"
export const SPEEDTEST_URL = "https://api.bkapp.gq/speedtest/"
export const METAL_URL = "https://10.6.0.5:9011/metal/"
export const FUEL_URL = "https://10.6.0.5:9011/fuel/"
export const IP_URL = "https://api.bkapp.gq/bsnl/ip/"
export const DNS_URL = "https://api.bkapp.gq/bsnl/dns/"
export const PING_URL = "https://api.bkapp.gq/ping/"
export const LOG_URL = "https://api.bkapp.gq/log/"

// export const IP_URL = "http://bk:8010/bsnl/ip/"
// export const DNS_URL = "http://bk:8010/bsnl/dns/"
// export const PING_URL = "http://bk:5000/ping/"
