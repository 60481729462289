import React, { Component } from 'react'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingHOC from './HOC/LoadingHOC'
import { DATAUSAGE_URL } from '../Constants'
import Moment from 'moment'

class Template extends Component {
  render () {
    return (
      <React.Fragment>
        {this.props.data_usages.error || this.props.data_usages.detail ? (
          <div className='content_head'>
            Error !!!
            <br></br>
            {this.props.data_usages.detail}
          </div>
        ) : (
          <React.Fragment>
            <div className='content_head'>
              {this.props.data_usages.rows &&
                this.props.data_usages.rows.map((data, i) => {
                  return (
                    <React.Fragment key='1'>
                      <h3>{data.dailyUsedOctets}</h3>
                      <span>Today Usage</span>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <h3>{data.totalOctets}</h3>
                      <span>Month Usage</span>
                    </React.Fragment>
                  )
                })}
            <table className='table'>

              <tbody>
                {this.props.data_usages.rows &&
                  this.props.data_usages.rows.map((data, i) => {
                    return (
                      <React.Fragment key='2'>
                        <tr>
                          <th scope='row'>DOWNLOAD</th>
                          <td>{data.downloadOctets}</td>
                        </tr>
                        <tr>
                          <th scope='row'>UPLOAD</th>
                          <td>{data.uploadOctets}</td>
                        </tr>
                      </React.Fragment>
                    )
                  })}
              </tbody>
            </table>
            
            <div className="wrap">
              <span>{Moment(this.props.currentTime).format('Do MMM YYYY hh:mm a')}</span>
            </div>
            </div>

          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

class Datausage extends Component {
  state = {
    data_usages: [],
    currentTime: new Date()
  }

  componentDidMount () {
    fetch(DATAUSAGE_URL)
      //fetch('https://bk.sytes.net:8050/datausage/')
      // fetch('http://192.168.1.11:3001/bsnltest/')
      .then(res => res.json())
      .then(
        data => {
          //fetch('https://app.fakejson.com/q/XgbFHd8a?token=NLCM7JCHBakv5H6OGlUXOw')
          //data = Array.from(data.rows);
          this.setState({ data_usages: data })
          console.log(this.state.data_usages)
        },
        error => {
          if (error) {
            this.setState({
              data_usages: { error: true, detail: error.message }
            })
            console.log(error)
          }
        }
      )
      .catch(console.log)
  }

  render () {
    return (
      <div id='content'>
        <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
          <div className='navbar-brand abs'>
            <h5>DATA USAGE</h5>
            <FontAwesomeIcon icon={faDownload} />
          </div>
        </nav>
        <Template1
          data_usages={this.state.data_usages}
          currentTime={this.state.currentTime}
        />
      </div>
    )
  }
}
// export default LoadingHOC('data_usages')(Datausage);
export default Datausage
const Template1 = LoadingHOC('data_usages')(Template)
export { Template1 }
