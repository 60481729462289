import React, { Component } from 'react';
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingHOC from './HOC/LoadingHOC'
import { LOG_URL } from './../Constants'

class Template extends Component {
  render() {
		return (
      <React.Fragment> 
          {this.props.log_data.error || this.props.log_data.detail
          ?<div  className='content_head'> 
            Error !!!
            <br></br>
            {this.props.log_data.detail}
          </div>             
          :
          <React.Fragment> <div id="main_content" className='content_head'>       
              <span id="scroller" className='pre_line paddingtop20'>

                {this.props.log_data.log_text.map((data, index) => 
                      <React.Fragment key={index}>
                        {data}
                        <div className='line'></div>
                      </React.Fragment>
                )}


              </span>
            
          </div>
          
          </React.Fragment> 
          }
          </React.Fragment> 
  );
  }
}

class Logpage extends Component {
  state = {
    log_data: [],
  }
  
  componentDidMount() {
    fetch(LOG_URL)
    // fetch('https://bk.sytes.net:8050/fuel/')
    //fetch('http://192.168.1.11:3001/fuel/')
    .then(res => res.json())
    .then((data) => {
      this.setState({ log_data: data })
      console.log(this.state.log_data);
      
    }, (error) => {
        if (error){
          this.setState({log_data: {"error": true, "detail": error.message}})
          console.log(error);
        }
    })
    .catch(console.log)
  }

  render() {
		return (
      <div id="content">
          <div id="nav_content" >
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
              <div className="navbar-brand abs" >
              <h5>LOG</h5>
              <FontAwesomeIcon icon={faClipboardList} />
              </div>
          </nav>
        </div>
      <Template1 log_data={this.state.log_data} />
      </div>
  );
  }
} 
export default Logpage;
const Template1 = LoadingHOC('log_data')(Template);
export {Template1};
