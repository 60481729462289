import React, { Component } from 'react';
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingHOC from './HOC/LoadingHOC'
import { IP_URL, DNS_URL, PING_URL } from './../Constants'

class Template extends Component {
  render() {
		return (
      <React.Fragment> 
      {this.props.ip_data.error || this.props.ip_data.detail
      ?<div  className='content_head'> 
        Error !!!
        <br></br>
        {this.props.ip_data.detail}
      </div>             
      :
      <React.Fragment>        
          <table className="table">
            <tbody>
                <tr>
                    <th scope="row">ISP</th>
                    <td >{this.props.ip_data.isp}</td>
                </tr>
                <tr>
                    <th scope="row">IP</th>
                    <td >{this.props.ip_data.query}</td>
                </tr>            
                <tr>
                    <th scope="row">DNS</th>
                    <td >{this.props.ip_data.dns.geo}</td>
                </tr> 
                <tr>
                    <th scope="row">PING</th>
                    <td >{this.props.ip_data.ping}</td>
                </tr> 
            </tbody>
          </table>
          </React.Fragment> 
        }
      </React.Fragment>
  );
  }
}

class Ip extends Component {
  state = {
    ip_data: [],
  }
  
  componentDidMount() {
    Promise.all([fetch(IP_URL), fetch(DNS_URL), fetch(PING_URL)])

      .then(([res1, res2, res3]) => { 
         return Promise.all([res1.json(), res2.json(), res3.json()]) 
      })
      .then(([res1, res2, res3]) => {
        this.setState({ ip_data: {...res1, ...res2, ...res3} })
        console.log(this.state.ip_data);
        // set state in here

      }, (error) => {
        if (error){
          this.setState({ip_data: {"error": true, "detail": error.message}})
          console.log(error);
        }        
      });
  }

  render() {
		return (
      <div id="content">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
              <div className="navbar-brand abs" >
              <h5>IP & DNS</h5>
              <FontAwesomeIcon icon={faNetworkWired} />
              </div>
          </nav>
      <Template1 ip_data={this.state.ip_data} />
      </div>
  );
  }
} 
export default Ip;
const Template1 = LoadingHOC('ip_data')(Template);
export {Template1};
