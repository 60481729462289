import firebase from "../firebase";

const db = firebase.ref("/bk_data");

class BkDataService {
  getAll() {
    return db;
  }
}

export default new BkDataService();
