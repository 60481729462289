import React, { Component } from 'react';
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingHOC from './HOC/LoadingHOC'
import { SPEEDTEST_URL } from '../Constants';
import Moment from 'moment';

class Template extends Component{

  render(){
    return (
      <React.Fragment> 
          {this.props.speedtest_result.error || this.props.speedtest_result.detail
          ?<div  className='content_head'> 
            Error !!!
            <br></br>
            {this.props.speedtest_result.detail}
          </div>             
          :      
      <React.Fragment>
      <table className="table">
        <caption className='content_head'> <a href={this.props.speedtest_result.speedtest_data.result.url} target="_blank" rel="noopener noreferrer"> {Moment(this.props.speedtest_result.speedtest_data.timestamp).format('Do MMM YYYY hh:mm a')} </a></caption>
        <tbody>
    
        <React.Fragment key="2">
        <tr>
              <th scope="row">PING</th>
              <td >latency - {this.props.speedtest_result.speedtest_data.ping.latency}<br></br>
                   jitter  - {this.props.speedtest_result.speedtest_data.ping.jitter}<br></br>
                   packetloss - {this.props.speedtest_result.speedtest_data.packetLoss}
              </td>

          </tr>          
        <tr>
            <th scope="row">DOWNLOAD</th>
            <td >{(this.props.speedtest_result.speedtest_data.download.bandwidth/125000).toFixed(2)} Mb/s<br></br>{(this.props.speedtest_result.speedtest_data.download.bandwidth/1e+6).toFixed(2)} MB/s</td>          
        </tr>
        <tr>
            <th scope="row">UPLOAD</th>
            <td >{(this.props.speedtest_result.speedtest_data.upload.bandwidth/125000).toFixed(2)} Mb/s<br></br>{(this.props.speedtest_result.speedtest_data.upload.bandwidth/1e+6).toFixed(2)} MB/s</td>
        </tr>
        <tr>
            <th scope="row">SERVER</th>
            <td >{this.props.speedtest_result.speedtest_data.server.name} | {this.props.speedtest_result.speedtest_data.server.location}</td>
        </tr>
        <tr>
            <th scope="row">IP</th>
            <td >{this.props.speedtest_result.speedtest_data.interface.externalIp}</td>
        </tr>
        </React.Fragment>



        </tbody>
      </table>
      <div className="line"></div>
      </React.Fragment> 
    }
    </React.Fragment>
    );
  }
}

class Speedtest extends Component {
  state = {
    speedtest_result: null,
    page_click: null
    }

  fetchData = async () => {
    this.setState({speedtest_result: null})
    this.setState({page_click: true})
    //fetch('http://192.168.1.11:3001/speedtestdummy/')
    // fetch('https://bk.sytes.net:8050/speedtest/')
    fetch(SPEEDTEST_URL)
    .then(res => res.json())
    .then((data) => {
      this.setState({ speedtest_result: data })
      console.log(this.state.speedtest_result);     
    }, (error) => {
      if (error){
        this.setState({speedtest_result: {"error": true, "detail": error.message}})
        console.log(error);
      }      
      
    })
    .catch(console.log)
  };

  render() {
		return (
      <React.Fragment>
        <div id="content">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
              <div className="navbar-brand abs" >
              <h5>SPEED TEST</h5>
              <FontAwesomeIcon icon={faTachometerAlt} />
              </div>
          </nav>
          <div className='speedtest'>
            <button className="fetch-button" onClick={this.fetchData} >
              Start
            </button> 
            <br></br>
            {this.state.page_click && 
              <Template1 speedtest_result={this.state.speedtest_result} />
            }
          </div>
        </div>
          </React.Fragment> 
  );
  }
} 
export default Speedtest;

const Template1 = LoadingHOC('speedtest_result')(Template);
export {Template1};
