import React, { Component } from 'react';
import { faGem } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingHOC from './HOC/LoadingHOC'
import { METAL_URL } from '../Constants';
import Moment from 'moment';

class Template extends Component {
  render() {
		return (
      <React.Fragment> 
      {this.props.metal_data.error || this.props.metal_data.detail
      ?<div  className='content_head'> 
        Error !!!
        <br></br>
        {this.props.metal_data.detail}
      </div>             
      :
      <React.Fragment>        
          <div className='content_head'>
            <h4>{Moment(this.props.metal_data.date, "DD/MMMM/YYYY").format('Do MMM YYYY')}</h4>
          </div>
            <br></br>
          <h4 className="textCenter">GOLD</h4>
          <br></br>
          <h5 className="textCenter">Standard Gold (22ct)</h5>
          <table className="table">
            <tbody>
                <tr>
                    <th scope="row">1 gm</th>
                    <td >{'₹ '+this.props.metal_data.gold.standard_gold}</td>
                </tr>
                <tr>
                    <th scope="row">1 sovereign</th>
                    <td >{'₹ '+this.props.metal_data.gold.standard_gold *8}</td>
                </tr>            
            </tbody>
          </table>
          <h5 className="textCenter">Pure Gold (24ct)</h5>
          <table className="table">
            <tbody>
                <tr>
                    <th scope="row">1 gm</th>
                    <td >{'₹ '+this.props.metal_data.gold.pure_gold}</td>
                </tr>
                <tr>
                    <th scope="row">1 sovereign</th>
                    <td >{'₹ '+this.props.metal_data.gold.pure_gold *8}</td>
                </tr>            
            </tbody>
          </table>
          <br></br>
          <h4 className="textCenter">SILVER</h4>
          <h6 className="textCenter">1 gm {'₹ '+this.props.metal_data.silver}</h6>

          </React.Fragment> 
     }
      </React.Fragment>
  );
  }
}

class Metal extends Component {
  state = {
    metal_data: [],
  }
  
  componentDidMount() {
    fetch(METAL_URL)
    // fetch('https://bk.sytes.net:8050/metal/')
    //fetch('http://192.168.1.11:3001/metal/')
    .then(res => res.json())
    .then((data) => {
      this.setState({ metal_data: data })
      console.log(this.state.metal_data);
    }, (error) => {
      if (error){
        this.setState({metal_data: {"error": true, "detail": error.message}})
        console.log(error);
      }      
    })
    .catch(console.log)
  }

  render() {
		return (
      <div id="content">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
              <div className="navbar-brand abs" >
              <h5>GOLD & SILVER</h5>
              <FontAwesomeIcon icon={faGem} />
              </div>
          </nav>
      <Template1 metal_data={this.state.metal_data} />
      </div>
  );
  }
} 
export default Metal;
const Template1 = LoadingHOC('metal_data')(Template);
export {Template1};
