import React, { Component } from 'react';
import DarkModeToggle from './darkmode';
import LoadingHOC from './HOC/LoadingHOC'
import BkDataService from '../services/bk.service'
import Moment from 'moment'


class Template extends Component {
  render () {
    return (
      <React.Fragment>
        {this.props.bk_data.error || this.props.bk_data.detail ? (
          <div className='content_head'>
            Error !!!
            <br></br>
            {this.props.bk_data.detail}
          </div>
        ) : (
          <React.Fragment>
            <div className='content_head'>
                    <React.Fragment key='1'>
                      <h3>{this.props.bk_data.usage.dailyUsedOctets}  |  {this.props.bk_data.usage.totalOctets}</h3>
                      <span>data usage</span>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <h3>{this.props.bk_data.ip.query}</h3>
                      <span>ip</span>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <h3>{this.props.bk_data.ping}</h3>
                      <span>ping</span>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      {/* <div className='pre_wrap'>
                      <span className='pre_line'>{this.props.bk_data.log}</span></div>
                      <span>log</span> */}
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <div className="wrap">
                       <span>{Moment(this.props.bk_data.last_updated).format('Do MMM YYYY hh:mm a')}</span>
                      </div>
                      
                    </React.Fragment>


            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}


class Homepage extends Component{
  state = {
    bk_data: []
  }

  getUserData = () => {
    BkDataService.getAll().on('value', snapshot => {
      const state = snapshot.val();
      console.log(state);
      this.setState({ bk_data: state})
      this.setState(state);
      console.log(this.state.bk_data);

    });
    console.log('DATA RETRIEVED');
  }


  componentDidMount() {
    this.getUserData();
  }

  render(){
    return (
      <div id="content">          
          <div className="navbar">
            <h2>bk app</h2>
            <DarkModeToggle />
          </div>
          <Template1
          bk_data={this.state.bk_data}
        />
      </div>
    );
  }
}

export default Homepage
const Template1 = LoadingHOC('bk_data')(Template)
export { Template1 }
