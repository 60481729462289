import firebase from "firebase/app";
import "firebase/database";

let config = {
  apiKey: "AIzaSyC23WCfWstZukB3AfNvU-jiglwAljcwePw",
  authDomain: "bk-app-7bfb8.firebaseapp.com",
  databaseURL: "https://bk-app-7bfb8.firebaseio.com",
  projectId: "bk-app-7bfb8",
  storageBucket: "bk-app-7bfb8.appspot.com",
  messagingSenderId: "862476039178",
  appId: "1:862476039178:web:51864ecade8c698c889bf3",
};

firebase.initializeApp(config);

export default firebase.database();
