import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
}
from "react-router-dom";
import Datausage from './components/datausage'
import Sidebar from './components/sidebar'
import Speedtest from './components/speedtest'
import Homepage from './components/homepage'
import Fuel from './components/fuel'
import Metal from './components/metal'
import Ip from './components/ip'
import Logpage from './components/logpage'

class App extends Component {
  
  render() {
    return (
     //<div> tttt</div>
    <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/speedtest/">
            <Sidebar speedtest={true} />
            <Speedtest />
          </Route>

          <Route path="/ip/">
            <Sidebar ip={true} />
            <Ip />
          </Route>

          <Route path="/datausage/">
            <Sidebar datapage={true} />
            <Datausage />           
          </Route>

          <Route path="/log/">
            <Sidebar logpage={true} />
            <Logpage />           
          </Route>

          <Route path="/fuel/">
            <Sidebar fuelpage={true} />
            <Fuel />           
          </Route>

          <Route path="/metal/">
            <Sidebar metalpage={true} />
            <Metal />           
          </Route>

          <Route path="">
            <Sidebar homepage={true} />
            <Homepage />
          </Route>
        </Switch>
    </Router>     

    )
  }

}

export default App;