import React, { Component } from 'react';
import { faDownload, faTachometerAlt, faGasPump, faHome, faGem, faNetworkWired , faClipboardList} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
  

class Sidebar extends Component {
    render() {
        return (
        <React.Fragment>
                <nav id="sidebar" className="active">
                    <div className="sidebar-header">
                       <Link to="/"><FontAwesomeIcon icon={faHome} className={this.props.homepage? "": "greyiconcolor"}/></Link>
                    </div>
                    {this.props.speedtest}
                    <ul className="list-unstyled components">
                        <li className="active">
                            <Link to="/datausage/"><FontAwesomeIcon icon={faDownload} className={this.props.datapage? "": "greyiconcolor"}/></Link>                            
                        </li>
                        <li>
                        <Link to="/ip/"><FontAwesomeIcon icon={faNetworkWired} className={this.props.ip? "": "greyiconcolor"}/></Link>
                        </li>
                        <li>
                        <Link to="/speedtest/"><FontAwesomeIcon icon={faTachometerAlt} className={this.props.speedtest? "": "greyiconcolor"}/></Link>
                        </li>
                        <li>
                        <Link to="/log/"><FontAwesomeIcon icon={faClipboardList} className={this.props.logpage? "": "greyiconcolor"}/></Link>
                        </li>
                        <li>
                        <Link to="/fuel/"><FontAwesomeIcon icon={faGasPump} className={this.props.fuelpage? "": "greyiconcolor"}/></Link>
                        </li>
                        <li>
                        <Link to="/metal/"><FontAwesomeIcon icon={faGem} className={this.props.metalpage? "": "greyiconcolor"}/></Link>
                        </li>

                    </ul>           
                </nav>
        </React.Fragment>
            );
        }
    } 
export default Sidebar;  