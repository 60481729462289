import React, { Component } from 'react';
import { faGasPump } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingHOC from './HOC/LoadingHOC'
import { FUEL_URL } from './../Constants'
import Moment from 'moment';

class Template extends Component {
  render() {
		return (
      <React.Fragment> 
          {this.props.fuel_data.error || this.props.fuel_data.detail
          ?<div  className='content_head'> 
            Error !!!
            <br></br>
            {this.props.fuel_data.detail}
          </div>             
          :
          <React.Fragment> <div className='content_head'>          
            <h4> {Moment(this.props.fuel_data.date, "DD-MM-YYYY").format('Do MMM YYYY')} </h4>
          </div>
          <br></br>
          <table className="table">
            <tbody>
                <tr>
                    <th scope="row">PETROL</th>
                    <td >{this.props.fuel_data.petrol}</td>
                </tr>
                <tr>
                    <th scope="row">DIESEL</th>
                    <td >{this.props.fuel_data.diesel}</td>
                </tr>            
            </tbody>
          </table>
          </React.Fragment> 
          }
          </React.Fragment> 
  );
  }
}

class Fuel extends Component {
  state = {
    fuel_data: [],
  }
  
  componentDidMount() {
    fetch(FUEL_URL)
    // fetch('https://bk.sytes.net:8050/fuel/')
    //fetch('http://192.168.1.11:3001/fuel/')
    .then(res => res.json())
    .then((data) => {
      this.setState({ fuel_data: data })
      console.log(this.state.fuel_data);
      
    }, (error) => {
        if (error){
          this.setState({fuel_data: {"error": true, "detail": error.message}})
          console.log(error);
        }
    })
    .catch(console.log)
  }

  render() {
		return (
      <div id="content">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
              <div className="navbar-brand abs" >
              <h5>FUEL</h5>
              <FontAwesomeIcon icon={faGasPump} />
              </div>
          </nav>
      <Template1 fuel_data={this.state.fuel_data} />
      </div>
  );
  }
} 
export default Fuel;
const Template1 = LoadingHOC('fuel_data')(Template);
export {Template1};
